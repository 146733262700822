import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'

Vue.use(Router)

const router = new Router({
   routes: [
      {
         name: 'followPaperInfoExpress_oRa',
         path: '/followPaperInfoExpress_oRa',
         component: () => import('@/views/reportManagement/followPaperInfoExpress_oRa.vue'),
         meta: { title: '轨迹展示' }
      },
      {
         path: '/',
         redirect: '/login'
      },

      {
         name: 'login',
         path: '/login',
         component: () => import('@/views/login.vue'),
         meta: { title: '登录页' }
      },
      {
         name: 'echarts',
         path: '/echarts',
         component: () => import('@/views/echarts.vue'),
         meta: { title: 'echarts首页' }
      },
      {
         name: 'login_NoPassL',
         path: '/login_NoPassL',
         component: () => import('@/views/login_NoPassL.vue'),
         meta: { title: '登录页' }
      },
      // ec系统客户使用的列表查询页面
      {
         name: 'ecSignBill',
         path: '/ecSignBill',
         component: () => import('@/views/waybillManagement/ecSignBill.vue'),
         meta: { title: 'ec系统列表查询页' }
      },
      // 城市配
      {
         name: 'cityUserReg',
         path: '/cityUserReg',
         component: () => import('@/views/reg/cityUserReg.vue'),
         meta: { title: '用户注册页' }
      },
      {
         name: 'cityCarrierReg',
         path: '/cityCarrierReg',
         component: () => import('@/views/reg/cityCarrierReg.vue'),
         meta: { title: '承运商注册页' }
      },
      {
         name: 'cityDriverReg',
         path: '/cityDriverReg',
         component: () => import('@/views/reg/cityDriverReg.vue'),
         meta: { title: '司机注册页' }
      },
      // 泰易达
      {
         name: 'cityUserRegTyd',
         path: '/cityUserRegTyd',
         component: () => import('@/views/reg/cityUserRegTyd.vue'),
         meta: { title: '用户注册页' }
      },
      {
         name: 'cityCarrierRegTyd',
         path: '/cityCarrierRegTyd',
         component: () => import('@/views/reg/cityCarrierRegTyd.vue'),
         meta: { title: '承运商注册页' }
      },
      {
         name: 'cityDriverRegTyd',
         path: '/cityDriverRegTyd',
         component: () => import('@/views/reg/cityDriverRegTyd.vue'),
         meta: { title: '司机注册页' }
      },
      // 正泰股份
      {
         name: 'cityUserRegChint',
         path: '/cityUserRegChint',
         component: () => import('@/views/reg/cityUserRegChint.vue'),
         meta: { title: '用户注册页' }
      },
      {
         name: 'cityCarrierRegChint',
         path: '/cityCarrierRegChint',
         component: () => import('@/views/reg/cityCarrierRegChint.vue'),
         meta: { title: '承运商注册页' }
      },
      {
         name: 'cityDriverRegChint',
         path: '/cityDriverRegChint',
         component: () => import('@/views/reg/cityDriverRegChint.vue'),
         meta: { title: '司机注册页' }
      },
      {
         name: 'reg',
         path: '/reg',
         component: () => import('@/views/reg.vue'),
         meta: { title: '注册页面' }
      },
      {
         name: 'regTyd',
         path: '/regTyd',
         component: () => import('@/views/regTyd.vue'),
         meta: { title: '租户注册页面' }
      },
      {
         name: 'regChint',
         path: '/regChint',
         component: () => import('@/views/regChint.vue'),
         meta: { title: '正泰电器股份注册页面' }
      },
      {
         name: 'forgetPassword',
         path: '/forgetPassword',
         component: () => import('@/views/user/forgetPassword.vue'),
         meta: { title: '忘记密码' }
      },
      {
         path: '/home',
         component: () => import('@/components/homepage/homepage.vue'),
         meta: {
            title: ''
         },
         children: [
            //********************系统首页及用户页面 Start********************************
            {
               path: '/index',
               name: '系统首页',
               component: () => import('@/views/HelloWorld.vue'),
               meta: { title: '系统首页', hideclose: true }
            },
            {
               name: 'changePassword',
               path: '/changePassword',
               component: () => import('@/views/user/changePassword.vue'),
               meta: { title: '修改密码' }
            },
            //********************系统首页及用户页面 Start********************************

            //********************城市配管理模块业务菜单 Start********************************
            //********************城市配管理模块业务管理二级子菜单 Start********************************
            {
               name: 'planWaybill',
               path: '/planWaybill',
               component: () => import('@/views/waybillManagement/planWaybill.vue'),
               meta: { title: '计划运单' }
            },
            {
               name: 'waybillManagement',
               path: '/waybillManagement',
               component: () => import('@/views/waybillManagement/waybillManagement.vue'),
               meta: { title: '运单管理' }
            },
            {
               name: 'deliveryBill',
               path: '/deliveryBill',
               component: () => import('@/views/waybillManagement/deliveryBill.vue'),
               meta: { title: '派单管理' }
            },
            {
               name: 'customerSignEval',
               path: '/customerSignEval',
               component: () => import('@/views/waybillManagement/customerSignEval.vue'),
               meta: { title: '客户签收管理' }
            },
            {
               name: 'abnormalFeedback',
               path: '/abnormalFeedback',
               component: () => import('@/views/waybillManagement/abnormalFeedback.vue'),
               meta: { title: '异常反馈管理' }
            },
            //********************城市配管理模块业务管理二级子菜单 End********************************

            //********************城市配管理模块基础数据管理二级子菜单 Start********************************
            {
               name: 'userUrbanManagement',
               path: '/userUrbanManagement',
               component: () => import('@/views/basicData/userUrbanManagement.vue'),
               meta: { title: '用户管理' }
            },
            {
               name: 'companyUrbanManagement',
               path: '/companyUrbanManagement',
               component: () => import('@/views/basicData/companyUrbanManagement.vue'),
               meta: { title: '公司管理' }
            },
            {
               name: 'carrierData',
               path: '/carrierData',
               component: () => import('@/views/basicData/platformFile.vue'),
               meta: { title: '承运商信息管理' }
            },
            {
               name: 'vehicleData',
               path: '/vehicleData',
               component: () => import('@/views/basicData/vehicleData.vue'),
               meta: { title: '车辆管理' }
            },
            {
               name: 'driverUrbanManagement',
               path: '/driverUrbanManagement',
               component: () => import('@/views/basicData/driverUrbanManagement.vue'),
               meta: { title: '司机文件管理' }
            },
            {
               name: 'routePlanning',
               path: '/routePlanning',
               component: () => import('@/views/basicData/routePlanning.vue'),
               meta: { title: '线路规划' }
            },
            {
               name: 'userUrbanBusinessManagement',
               path: '/userUrbanBusinessManagement',
               component: () => import('@/views/basicData/userUrbanBusinessManagement.vue'),
               meta: { title: '业务员管理' }
            },
            {
               name: 'warehouseUrbanManagement',
               path: '/warehouseUrbanManagement',
               component: () => import('@/views/basicData/warehouseUrbanManagement.vue'),
               meta: { title: '库房管理' }
            },
            {
               name: 'consignerUrbanManagement',
               path: '/consignerUrbanManagement',
               component: () => import('@/views/basicData/consignerUrbanManagement.vue'),
               meta: { title: '发货方管理' }
            },
            {
               name: 'consumerUrbanManagement',
               path: '/consumerUrbanManagement',
               component: () => import('@/views/basicData/consumerUrbanManagement.vue'),
               meta: { title: '收货方管理' }
            },
            //********************城市配管理模块业务管理二级子菜单 End********************************

            //********************城市配管理模块报表管理二级子菜单 Start********************************
            {
               name: 'waybillTrack',
               path: '/waybillTrack',
               component: () => import('@/views/reportManagement/waybillTrack.vue'),
               meta: { title: '运单轨迹查询' }
            },
            {
               name: 'carsTrack',
               path: '/carsTrack',
               component: () => import('@/views/reportManagement/carsTrack.vue'),
               meta: { title: '车次轨迹查询' }
            },
            {
               name: 'otherPlatformReport',
               path: '/otherPlatformReport',
               component: () => import('@/views/reportManagement/otherPlatformReport.vue'),
               meta: { title: '第三方物流平台周报' }
            },
            //********************城市配管理模块报表管理二级子菜单 End********************************

            //********************城市配管理模块接口监控管理二级子菜单 Start********************************
            {
               name: 'interfaceMonit',
               path: '/interfaceMonit',
               component: () => import('@/views/programInterface/interfaceMonit.vue'),
               meta: { title: '接口监控' }
            },
            //********************城市配管理模块接口监控管理二级子菜单 End********************************
            //********************城市配管理模块业务菜单 End********************************

            //********************权限管理模块业务菜单 Start********************************

            {
               name: 'userManagement',
               path: '/userManagement',
               component: () => import('@/views/authorManagement/userManagement.vue'),
               meta: { title: '用户管理' }
            },
            {
               name: 'roleApproval',
               path: '/roleApproval',
               component: () => import('@/views/authorManagement/roleApproval.vue'),
               meta: { title: '角色管理' }
            },
            {
               name: 'rolePermissions',
               path: '/rolePermissions',
               component: () => import('@/views/authorManagement/rolePermissions.vue'),
               meta: { title: '菜单管理' }
            },
            {
               name: 'rolePermissionAllTree',
               path: '/rolePermissionAllTree',
               component: () => import('@/views/authorManagement/rolePermissionAllTree.vue'),
               meta: { title: '菜单树' }
            },
            {
               name: 'companyManagement',
               path: '/companyManagement',
               component: () => import('@/views/authorManagement/companyManagement.vue'),
               meta: { title: '公司管理' }
            },
            {
               name: 'driverFile',
               path: '/driverFile',
               component: () => import('@/views/authorManagement/driverFile.vue'),
               meta: { title: '司机文件管理' }
            },
            {
               name: 'userBusinessManagement',
               path: '/userBusinessManagement',
               component: () => import('@/views/authorManagement/userBusinessManagement.vue'),
               meta: { title: '业务员管理' }
            },
            {
               name: 'tenantManagement',
               path: '/tenantManagement',
               component: () => import('@/views/authorManagement/tenantManagement.vue'),
               meta: { title: '租户管理' }
            },



            //********************权限管理模块业务菜单 End********************************

            //********************定时器管理模块业务菜单 Start********************************

            {
               name: 'quartzConfig',
               path: '/quartzConfig',
               component: () => import('@/views/quartzManagement/quartzConfig.vue'),
               meta: { title: '定时器配置' }
            },
            {
               name: 'quartzControl',
               path: '/quartzControl',
               component: () => import('@/views/quartzManagement/quartzControl.vue'),
               meta: { title: '定时器控制' }
            },
            //********************定时器管理模块业务菜单 End********************************
         ]
      },
      //********************高德轨迹地图展示页面 Start********************************
      //注意 publicMapTrack_oRa_permit publicMapTrack_oRa_phone 这两个页面是外接给其它系统使用，没有验证用户，其余两个必须登录城市配系统才可以使用
      {
         name: 'publicMapTrack',
         path: '/publicMapTrack',
         component: () => import('@/views/reportManagement/publicMapTrack.vue'),
         meta: { title: '轨迹展示' }
      },
      {
         name: 'publicMapTrack_oRa',
         path: '/publicMapTrack_oRa',
         component: () => import('@/views/reportManagement/publicMapTrack_oRa.vue'),
         meta: { title: '轨迹展示' }
      },
      {
         name: 'publicMapTrack_oRa_permit',
         path: '/publicMapTrack_oRa_permit',
         component: () => import('@/views/reportManagement/publicMapTrack_oRa_permit.vue'),
         meta: { title: '轨迹展示' }
      },
      {
         name: 'publicMapTrack_oRa_phone',
         path: '/publicMapTrack_oRa_phone',
         component: () => import('@/views/reportManagement/publicMapTrack_oRa_phone.vue'),
         meta: { title: '轨迹展示' }
      },
      // {
      //     name: 'followPaperInfoExpress_oRa',
      //     path: '/followPaperInfoExpress_oRa',
      //     component: () => import('@/views/reportManagement/followPaperInfoExpress_oRa.vue'),
      //     meta: { title: '轨迹展示' }
      // },
      //********************高德轨迹地图展示页面 End********************************
      {
         name: 'transport',
         path: '/transport',
         component: () => import('@/views/reportManagement/transport.vue'),
         meta: { title: '运输托运单' }
      },
      {
         name: 'transportpp',
         path: '/transportpp',
         component: () => import('@/views/reportManagement/transportpp.vue'),
         meta: { title: '运输托运单自适应' }
      },

      {
         name: '403',
         path: '/403',
         component: () => import('@/components/homepage/403.vue'),
         meta: { title: '资源不可访问' }
      },
      {
         name: '404',
         path: '/404',
         component: () => import('@/components/homepage/404.vue'),
         meta: { title: '路由不存在' }
      },
      {
         name: '500',
         path: '/500',
         component: () => import('@/components/homepage/500.vue'),
         meta: { title: '客户号不能为空' }
      },
      {
         name: 'overLogut',
         path: '/overLogut',
         component: () => import('@/components/homepage/overLogut.vue'),
         meta: { title: '异常' }
      },
      {
         path: '*',
         redirect: '/404'
      },
   ],
   mode: 'hash'
})

export default router
