import Vue from 'vue'
import App from './App'
Vue.config.productionTip = false
//包引用------start
//0.引入babel-polyfill,兼容Ie,将es6转为es5
import 'babel-polyfill'

//1.引入element-ui
import ElementUI from 'element-ui'
//默认样式
// import 'element-ui/lib/theme-chalk/index.css'
//自定义样式
import "../static/theme/theme-blue/index.css";
Vue.use(ElementUI)
//后引入路由,组件内样式可以覆盖elementui样式
import router from './router'


//2.引入axios
import axios from 'axios';
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios;//将axios改写到vue原型属性,调用方式例如this.$http.post(xxx)

/*本地开发环境 内网*/
// Vue.prototype.$apiPath = 'http://api-t.zjtyd.com'; //接口访问地址
// Vue.prototype.$filePath = 'http://api-t.zjtyd.com/zuul/api/upload/image'; //文件上传地址
// Vue.prototype.$imageiPPath = 'http://10.10.19.98/'; //图片路径访问前缀

/*测试环境 外网*/
Vue.prototype.$apiPath = 'https://api-t.zjtyd.com';
Vue.prototype.$filePath = 'https://api-t.zjtyd.com/zuul/api/upload/image';
Vue.prototype.$imageiPPath = 'https://file-t.zjtyd.com/';

/*正式环境 外网*/
// Vue.prototype.$apiPath = 'https://capi.zjtyd.com';
// Vue.prototype.$filePath = 'https://capi.zjtyd.com/zuul/api/upload/image';
// Vue.prototype.$imageiPPath = 'https://file-t.zjtyd.com/';

/*正式环境 内网*/
// Vue.prototype.$apiPath = 'http://capi.zjtyd.com';
// Vue.prototype.$filePath = 'http://capi.zjtyd.com/zuul/api/upload/image';
// Vue.prototype.$imageiPPath = 'http://file-t.zjtyd.com/';


//3.引入vuex
import Vuex from 'vuex'
Vue.use(Vuex)

//4.引入ckeditor
// import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'

//5.引入moment,表格日期格式化
import moment from 'moment'
Vue.prototype.$moment = moment;//设置到vue原型属性,调用方式this.$moment(date).format("YYYY-MM-DD HH:mm:ss");

// 将自动注册所有组件为全局组件

//6.引入导入组件
import EleImport from './components/excelData/index'
Vue.component(EleImport.name, EleImport);

import store from './store/index'
Vue.use(store)

import AMap from 'vue-amap';
Vue.use(AMap);
AMap.initAMapApiLoader({
   key: '1b24008a664d01ae56a7bd1db67a1872',
   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
   v: '1.4.4'
})

import echarts from 'echarts'
Vue.prototype.echarts = echarts;


import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;

Vue.use(VueCookies)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import getDatel from './components/common/userToken'
global.getDatel = getDatel;
//包引用------end
// 添加全局事件监控方法
Vue.prototype.resetSetItem = function(key, newVal) {
   if (key === 'watchStorage') {

      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
         setItem: function(k, val) {
            sessionStorage.setItem(k, val);

            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

            // 派发对象
            window.dispatchEvent(newStorageEvent)
         }
      }
      return storage.setItem(key, newVal);
   }
}
// 响应拦截器
axios.interceptors.response.use(
   response => {
      if (response.status === 200) {
         return Promise.resolve(response);
      } else {
         return Promise.reject(response);
      }
   },
   // 服务器状态码不是200的情况
   error => {
      if (error.response.status) {
         switch (error.response.status) {
            // 401/403 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
               ElementUI.MessageBox({
                  title: '提示',
                  message: '未登录'
               });
               router.replace({
                  name: 'login'
               });
               break;
            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
               router.replace({
                  name: '403',
               });
               break;
            // 404请求不存在
            case 404:
               // Toast({
               //     message: '网络请求不存在',
               //     duration: 1500,
               //     forbidClick: true
               // });
               break;
            case 500:
               ElementUI.MessageBox({
                  title: '提示',
                  message: '服务暂不可用，请稍后再试'
               });
               break;
            case 504:
               ElementUI.MessageBox({
                  title: '提示',
                  message: '后台返回超时，请稍后重试'
               });
               break;
            // 其他错误，直接抛出错误提示
            default:
               // ElementUI.MessageBox({
               //     title: '提示',
               //     message: error.response.data.msg
               // });
               router.replace({
                  name: 'overLogut',
               });
               break;
         }
         return Promise.reject(error.response);
      }
   }
);
/* eslint-disable no-new */
new Vue({
   el: '#app',
   router,
   store,
   components: { App },
   template: '<App/>'
})

