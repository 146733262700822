/**
 * Created by think on 2021/6/15.
 */
const developer = {
    namespaced: true,
    state: {
        developerInfo: {},
    },
    getters: {
        getDeveloperInfo: state => state.developerInfo,
    },
    mutations: {
        setDeveloperInfo(state, data) {
            state.developerInfo = data;
        },
    },
    actions: {
        setDeveloperInfo({ commit }, { data }) {
            commit('setDeveloperInfo', data);
        },
    }
};

export default developer;
